@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.loginForm {
  width: 100%;
  display: flex;
  flex-direction: column;

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.44rem;
    position: relative;

    .forgotPassword {
      color: $primary-main;
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-decoration-line: underline;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        color: $primary-dark;
      }
    }

    > :nth-child(n + 2) {
      margin-top: 2.125rem;
    }

    > :last-child {
      margin-top: 0;
    }

    > div {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      :global(.MuiFormLabel-root) {
        font-family: $font-manrope;
        font-size: 1.125rem;
        color: $black-color;
        margin-top: -1rem;
      }

      @include bp(m) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}
