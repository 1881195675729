@import '../../styles/variables';
@import '../../styles/mixins';

.navigationContainer {
  display: flex;
  justify-content: center;
  font-family: $font-manrope;

  .mobileNavigation {
    width: 100%;
    padding: 2rem 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  width: 11.25rem;
}
