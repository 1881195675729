@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.authNavigationContainer {
  display: flex;
  justify-content: center;
  background: $primary-gradient;
  height: 5.875rem;

  .innerNavigation {
    width: 90%;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .searchbarContainer {
      width: 40%;

      @include bp(m) {
        width: 25%;
      }
    }

    .buttonContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 1.125rem;

      > button {
        font-size: 1rem;
        font-weight: 500;
        padding: 0.781rem 1.625rem;
        width: auto;
        text-transform: none;
      }
    }
  }

  .mobileMenuLinks {
    padding: 2rem 0;
    top: 5.625rem;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 99;
    box-shadow: $mobile-menu-links-shadow;
    background-color: $color-white;

    button {
      display: block;
      margin: 0 auto;
      width: auto;
    }
  }
}

.logo {
  width: 14.25rem;
}
