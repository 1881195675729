@import '../../../styles/variables';
@import '../../../styles/mixins';

.icon {
  background-color: $color-light-blue;
  width: 2.25rem;
  height: 2.2rem;
  border-radius: 0.25rem;

  button {
    padding: 0.5rem 0.3125rem !important;
  }
}
