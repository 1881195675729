@import '../../styles/mixins';
@import '../../styles/variables';

.modalContainer {
  .backButtonContainer {
    display: flex;
    margin-top: 1.37rem;
    margin-left: 0.62rem;
    gap: 0.19rem;
    color: $primary-main;
    transition: 0.2s;
    font-family: $font-manrope;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;

    &:hover {
      cursor: pointer;
      color: $primary-dark;
    }
  }

  .modalTitle {
    display: flex;
    justify-content: flex-end;
    font-family: $font-manrope !important;

    .isTitleCentered {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 2.5rem;
      width: 100%;
    }

    .isTitleLeftAligned {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
  }

  :global(.MuiDialog-paperScrollPaper) {
    border-radius: 1rem;
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    margin: 0;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 4rem);
    max-width: 75rem;
    box-shadow: rgba(145, 158, 171, 0.24) 0 0 0.25rem 0,
      rgba(145, 158, 171, 0.24) 0 1.5rem 3rem 0;

    @include bp(s) {
      min-width: 31.5rem;
      margin: 2rem;
    }

    h2 {
      margin: 0;
      font-weight: 700;
      line-height: 1.55556;
      font-family: $jost;
      flex: 0 0 auto;
      padding: 1.5rem 1.5rem 0;
      font-size: 1.0625rem;

      @include bp(s) {
        font-size: 1.125rem;
      }
    }

    :global(.MuiIconButton-root:hover) {
      background-color: transparent;
    }

    :global(.MuiDialogContent-root) {
      padding: 0 1.5rem 3.38rem;
      flex: 1 1 auto;
      overflow-y: auto;
      border-top: 0;
      border-bottom: 0;

      &::-webkit-scrollbar {
        background-color: $certificate-form-sidebar-panel-color;
        width: 0.5rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $dark-grey;
        border-radius: 0.375rem;
      }

      :global(.MuiDialogContentText-root) {
        margin: 0;
        line-height: 1.5;
        font-size: 0.875rem;
        font-family: $jost;
        font-weight: 400;
        color: rgb(99, 115, 129);
      }
    }

    :global(.MuiDialogActions-root) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 0 0 auto;
      padding: 1.5rem;
    }
  }

  :global(.MuiBackdrop-root) {
    background-color: $background-black-lower-opacity;
  }

  .closeIcon {
    color: $color-light-grey-third;
  }

  &.isSmall {
    :global(.MuiDialog-paperScrollPaper) {
      max-width: 37.5rem;
    }
  }

  &.isFullWidth {
    :global(.MuiDialog-paperScrollPaper) {
      width: 100%;
    }
  }

  &.isTransparent {
    :global(.MuiDialog-paperScrollPaper) {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .isCloseButtonInContent {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    position: relative;
    justify-content: center;

    .closeButton {
      background-color: $modal-close-button;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.2rem;
      position: absolute;
      top: 0.3125rem;
      right: 0.5rem;

      @include bp(sm) {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: unset;
        position: unset;
      }

      svg {
        color: $color-white;
        width: 1rem;
        height: 1rem;

        @include bp(sm) {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  .explanationText {
    margin-top: 0.9375rem !important;
  }
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .zoomInfoIcon {
    display: none;

    @include bp(sm) {
      display: block;
    }
    > div {
      display: flex;
      height: 1.5rem;
      width: 1.5rem;

      @include bp(sm) {
        width: 2.5rem;
        height: 2.5rem;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
