@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.sectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp(s) {
    margin: 0 2.5rem;
  }

  @include bp(m) {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .title {
    text-align: center;
    font-family: $font-manrope;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: normal;
    margin: 0 auto;

    @include bp(s) {
      font-size: 1.875rem;
    }
  }

  .cards {
    margin-top: 1.38rem;
    display: grid;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    justify-content: center;

    @include bp(s) {
      margin-top: 3.44rem;
      gap: 1.69rem;
    }

    @include bp(sm) {
      grid-template-columns: repeat(2, minmax(21.25rem, 21.25rem));
      justify-content: center;
    }

    @include bp(m) {
      grid-template-columns: repeat(1, minmax(21.25rem, 21.25rem));
      justify-content: flex-start;
    }

    @include bp(l) {
      grid-template-columns: repeat(2, minmax(21.25rem, 21.25rem));
    }

    @include bp(xxxl) {
      grid-template-columns: repeat(4, minmax(21.25rem, 21.25rem));
    }
  }
}
