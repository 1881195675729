@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.authContainer {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  min-height: 100vh;
  gap: 3rem;

  @include bp(l) {
    flex-direction: row;
    gap: 0;
  }

  .leftContainer {
    width: 100%;

    @include bp(l) {
      width: 50%;
    }
  }

  .rightContainer {
    width: 100%;
    margin: auto;
    margin-top: 3rem;

    @include bp(l) {
      width: 50%;
      margin: auto;
    }

    .rightContainerInner {
      max-width: 30.875rem;
      margin-left: auto;
      margin-right: auto;
      background-color: $color-white;
      padding: 1rem;
      border-radius: 1rem;
      box-shadow: 0rem 0rem 1.25rem 0rem $background-black-low-opacity;

      @include bp(xxs) {
        padding: 2.12rem 3.34rem 2.12rem 3.27rem;
      }

      .title {
        font-family: $font-manrope;
        font-weight: 500;
        font-size: 1.875rem;
        text-align: left;
        margin-top: 1.25rem;
        margin-bottom: 1.75rem;
        color: $black-color;
      }
    }
  }
}
