@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.companyContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  margin-top: 2.3125rem;
  margin-bottom: 1.3125rem;
  color: $grey-text;
  font-size: 1.0625rem;
  font-weight: 400;
  cursor: default;

  div {
    display: flex;
    align-items: center;
  }

  .userInfoContainer {
    display: flex;
    gap: 0.375rem;

    .icon {
      color: $primary-main;
    }
  }

  .companySelectionContainer {
    margin-top: 1.5625rem;
    margin-bottom: 0.5rem;
    width: 100%;

    .companyIcon {
      color: $primary-main;
    }

    :global(.MuiAutocomplete-root) {
      width: 100%;
    }

    :global(.MuiAutocomplete-input) {
      pointer-events: none;
    }

    :global(.MuiOutlinedInput-root) {
      background-color: transparent;
      border: none;
      color: $grey-text;
      font-weight: 500;
      font-size: 1.0625rem;
      padding-left: 0 !important;
      cursor: pointer;

      ::selection {
        background: transparent;
      }

      input {
        cursor: pointer;
        padding-left: 0.375rem !important;
      }
    }
  }
}

.popper {
  border: 0.0625rem solid $color-light-grey;
  border-radius: 0.25rem;

  * {
    font-family: $font-manrope !important;
    font-size: 1.0625rem;
    font-weight: 400;
    color: $grey-text;
  }

  :global(.MuiAutocomplete-option[aria-selected='true']) {
    background-color: $primary-main-background !important;
    color: $primary-main;
    font-weight: 500;
  }
}

.signaturesContainer {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.125rem;
  margin-bottom: 2.125rem;

  .signatureCountContainer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
    min-width: 7.25rem;
    min-height: 4.25rem;
    flex-shrink: 0;
    border-radius: 0.375rem;
    background: $primary-main;
    color: $color-white;
    cursor: default;

    .count {
      font-size: 1.5rem;
      font-weight: 600;
    }

    .label {
      font-size: 0.9375rem;
      font-weight: 300;
    }
  }

  .fillSignaturesCta {
    color: $primary-main;
    font-size: 0.9375rem;
    font-weight: 500;
    text-decoration-line: underline;
    transition: 0.2s;

    &:hover {
      cursor: pointer;
      color: $primary-dark;
    }
  }
}
