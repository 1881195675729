@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.socialLogin {
  .loginError {
    margin-bottom: 1rem;
  }

  .googleButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    font-family: $font-manrope;

    div {
      display: flex;
      justify-content: center;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
