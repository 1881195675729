@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.remindPasswordContainer {
  max-width: 23.3125rem;
  font-family: $font-manrope;
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
  margin: 0 auto;

  .remindTitle {
    text-align: center;
    font-size: 1.875rem;
    font-weight: 500;
  }

  .remindSubTitle {
    text-align: center;
    font-size: 0.9375rem;
  }

  .remindForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2.44rem;
      position: relative;

      > div {
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        :global(.MuiFormLabel-root) {
          font-family: $font-manrope;
          font-size: 1.125rem;
          color: $black-color;
          margin-top: -1rem;
        }

        @include bp(m) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
