@import '../../styles/mixins';
@import '../../styles/variables';

.container {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;

    &:hover {
      transform: scale(2);
      transition: 0.3s ease-in-out;
    }
  }

  .content {
    .name {
      color: $primary-main;
      font-weight: 600;
    }
  }
}
