@import '../../styles/variables';

.button {
  height: 2.25rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: $color-white;

  &:global(.MuiButton-containedPrimary) {
    background-color: $primary-button-background;
    box-shadow: none;
    border-radius: 0.5rem;
    padding: 0.688rem 2.5rem;
    font-family: $font-manrope;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    width: 100%;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: $disabled-button-background;
      color: $color-grey-text-lighter;
    }

    &:hover {
      box-shadow: none;
      background-color: $primary-main-lighter;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    background-color: $color-grey-blue;
    box-shadow: none;
    border-radius: 0.5rem;
    padding: 0.688rem 2.5rem;
    font-family: $font-manrope;
    text-transform: none;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    color: $primary-main;
    width: 100%;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: $disabled-button-background;
    }

    &:hover {
      box-shadow: none;
      background-color: $color-grey-blue-darker;
    }
  }

  &:global(.MuiButton-outlinedPrimary) {
    background-color: unset;
    border: 0.063rem solid $primary-button-background;
    color: $primary-button-background;
    box-shadow: none;
    border-radius: 0.5rem;
    padding: 0.688rem 2.5rem;
    font-family: $font-manrope;
    text-transform: none;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.5rem;
    height: 3rem;
    width: 100%;

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: $disabled-button-background;
    }

    &:hover {
      box-shadow: none;
      background-color: $primary-button-background-hover;
      color: $primary-main;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 0.875rem;
    text-transform: none;
    font-family: $font-manrope;
    min-width: 4rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;
  }

  &.saveButton {
    height: auto;
    padding: 0.375rem 3rem;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
  }

  &.isActive {
    opacity: 1;
  }

  &.onlyIcon {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 2.5rem;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 0.25rem;
  }

  &.secondaryButton {
    font-family: $font-manrope;
    outline: none;
    border: none;
    color: $color-white;
    padding: 1.125rem 1.75rem;
    border-radius: 0.5rem;
    background-color: $color-lavander-blue;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: $color-lavander-blue-lightest;
      color: $color-lavander-blue;
    }
  }

  &.paymentButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: $font-manrope;
    outline: none;
    color: $black-color;
    font-size: 0.875rem;
    background-color: $color-white;
    cursor: pointer;
    box-shadow: 0rem 0rem 1.25rem 0rem $color-black-transparent;
    border-radius: 0.5rem;
    border: 0.0625rem solid $light-grey-background;
    padding: 1rem 1.09rem 1rem 1.25rem;
    font-weight: 500;

    > div:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.56rem;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:hover {
      color: $primary-main;
      border-color: $payment-button-border-hover;
      background-color: $payment-button-background-hover;
    }
  }

  &.secondaryLoadingButton {
    font-family: $font-manrope;
    outline: none;
    border: none;
    color: $primary-main;
    padding: 1.125rem 1.75rem;
    border-radius: 0.5rem;
    background-color: $color-grey-blue;
    text-align: center;
    font-weight: 500;
  }
}
