@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.sidebarItemsGroup {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  .sidebarItemContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 1.125rem;
    color: $grey-text;
    font-size: 1.0625rem;
    font-weight: 400;
    border-radius: 0.375rem;
    padding: 1rem 0 1rem 1.75rem;
    cursor: pointer;
    user-select: none;
    width: 15.9375rem;

    &.activeSidebarItem {
      background: $color-white;
      color: $primary-main;
      font-weight: 500;
    }

    &:hover {
      background: $background-light-grey;
    }

    div {
      width: 1.5625rem;
      display: flex;
      align-items: center;

      svg {
        width: 100%;
      }
    }
  }
}
