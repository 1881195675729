@import 'styles/variables';
@import 'styles/mixins';

*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: $font-inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    height: 100%;

    --toastify-color-success: #51ea1c;
    --toastify-font-family: $jost;
    --toastify-icon-color-success: #51ea1c;
    --toastify-color-progress-success: #51ea1c;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  padding: 0 3px 3px 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=')
    no-repeat bottom right;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
  display: none;
}

// Cambria font
@font-face {
  font-family: 'Cambria';
  src: url('./assets/fonts/cambria/Cambria-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cambria';
  src: url('./assets/fonts/cambria/Cambria-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Cambria';
  src: url('./assets/fonts/cambria/Cambria-Bold.ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Cambria';
  src: url('./assets/fonts/cambria/Cambria-BoldItalic.ttf');
  font-weight: 800;
  font-style: italic;
}

// Aileron font
@font-face {
  font-family: 'Aileron';
  src: url('./assets/fonts/aileron/Aileron-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aileron';
  src: url('./assets/fonts/aileron/Aileron-Italic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Aileron';
  src: url('./assets/fonts/aileron/Aileron-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Aileron';
  src: url('./assets/fonts/aileron/Aileron-SemiBoldItalic.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Aileron';
  src: url('./assets/fonts/aileron/Aileron-Bold.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Aileron';
  src: url('./assets/fonts/aileron/Aileron-BoldItalic.otf');
  font-weight: 800;
  font-style: italic;
}

// Questa
@font-face {
  font-family: 'Questa';
  src: url('./assets/fonts/questa/Questa-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Questa';
  src: url('./assets/fonts/questa/Questa-Italic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Questa';
  src: url('./assets/fonts/questa/Questa-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Questa';
  src: url('./assets/fonts/questa/Questa-Bold.otf');
  font-weight: 800;
  font-style: normal;
}
