$font-inter: 'Inter', sans-serif;
$font-poppins: 'Poppins', sans-serif;
$jost: 'Jost', sans-serif;
$dmSans: 'DM Sans', sans-serif;
$josefinSans: 'Josefin Sans', sans-serif;
$font-manrope: 'Manrope', sans-serif;
$font-manuale: 'Manuale', serif;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-main: #4f868e;
$primary-light: #2e8df0;
$primary-dark: #216d78;
$primary-extra-dark: #2e8b0e;
$primary-darker: $primary-extra-dark;
$primary-darkest: $primary-extra-dark;
$primary-light-background: rgba(66, 204, 18, 0.02);
$primary-gradient: linear-gradient(90deg, #4f868e 0.05%, #216d78 101.35%);
$primary-main-lighter: #72989e;
$primary-main-light: #90c5ce;
$primary-main-background: #4f868e1f;

$color-white: #ffffff;
$color-white-light: #f6f4ef;
$color-white-lighter: #f5f5f5;
$color-white-ghost: #f8fafb;

$primary-button-background: #4f868e;
$primary-button-background-hover: #e7ebed;
$primary-button-shadow: rgba(28, 49, 68, 0.12) 0 0.25rem 0.5rem 0;
$box-shadow: 0rem 0rem 0.3125rem 0rem rgba(0, 0, 0, 0.05);
$hover-shadow: 0rem 0rem 0.625rem 0rem rgba(79, 134, 142, 0.7);

$disabled-button-background: #f1f3f4;

$color-grey: rgb(126, 136, 143);
$color-grey-light: #d9d9d9;
$color-grey-light-2: #f0f0f0;
$color-grey-background: #f2f2f2;
$color-grey-background-dark: #e8e8e8;
$color-grey-very-light: #c9c9c9;
$color-grey-light-line: rgba(218, 222, 227, 0.1);
$color-grey-text: #8c8c8c;
$color-grey-text-lighter: #bababa;
$color-grey-rich-text: rgba(217, 217, 217, 0.2);
$color-grey-rich-text-hover: rgba(217, 217, 217, 0.3);
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-grey-translucent: #f9f9f9;
$color-grey-translucent-light: #f3f3f3;
$color-grey-darker: #f1f1f1;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;
$color-blue-steel: #405e8c;
$color-blue-soft-light: #e2e7fc;
$color-teal: #33cca0;
$color-teal-light: #72e2c2;
$color-green-bright: #11bc8b;

$label: #7d7f81;
$label-focused: $primary-dark;
$label-error: #d32f2f;

$input-border: rgba(145, 158, 171, 0.32);
$input-border-focused: $primary-light;
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$background-light-grey: rgba(145, 158, 171, 0.12);
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$lighter-bright-color: #b7b7b7;
$black-color: #000000;
$lighter-black-color: #1c1c1c;

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-more-darker: #b95e5e;
$red-color-brighter: #efc5bd;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$coming-soon-subheadline: rgb(99, 115, 129);

$gradient-color-blue-dark: #283f95;
$gradient-color-blue-light: #ced4ee;
$gradien-color-grey-light: #e2e4ec;

$color-white-opacity-50: rgba(255, 255, 255, 0.5);
$color-white-opacity-58: rgba(255, 255, 255, 0.58);
$color-white-opacity-70: rgba(255, 255, 255, 0.7);

$color-progress-bar-gradient-1: linear-gradient(0deg, #3961f5 0%, #72e2c2 100%);
$color-progress-bar-gradient-1-90deg: linear-gradient(
  90deg,
  #3961f5 0%,
  #72e2c2 100%
);
$color-progress-bar-gradient-2: linear-gradient(0deg, #72e2c2 0%, #3961f5 100%);
$color-progress-bar-gradient-2-90deg: linear-gradient(
  90deg,
  #72e2c2 0%,
  #3961f5 100%
);
$gradient-color-black: rgba(251, 251, 251, 0);
$grey-gradient: linear-gradient(0deg, #f9f9f9 0%, #f3f3f3 100%);
$gradient-color-light-grey: linear-gradient(180deg, #fcfcfc 0%, #f4f4f4 100%);

$lightest-blue-background: #e9f0fa;

$lightest-grey-background: #fbfbfb;
$light-grey-background: #eeeeee;
$dark-grey-text: #8c8c8c;

$color-breadcrumb: #cecece;
$darker-grey-text: #595959;
$light-grey-text: #b7b7b7;
$dark-grey-text: rgba(34, 51, 84, 0.7);

$orange-background: #ebb16b;
$organge-dark-text: #bd6800;
$orange-background-dark: #e5912b;
$orange-background-light: #ffe8cd;

$light-green-background: #72e2c2;
$light-green-background-dark: #11bc8b;
$light-green-background-light: #d2f3ea;

$pink-background: #fe2ebb;
$pink-background-dark: #fe2ebb;
$pink-background-light: #ffe7f7;

$previewCardTop: #1a1d24;
$pill-color: #405e8c;
$pill-background-color: #e9f0fa;

$assessment-button-color: #e5912b;
$dark-blue-button: #3462ff;

$color-very-light-grey: #f8f8f8;
$color-light-grey: #dadada;
$color-light-grey-second: #dedede;
$color-light-grey-third: #a4a4a4;
$color-grey-blue: #f1f3f4;
$color-grey-blue-darker: #dbe3e9;
$color-grey-transparent: rgba(255, 255, 255, 0.6);
$color-grey-more-transparent: rgba(255, 255, 255, 0.4);
$color-dark-grey: #868686;
$color-dark-grey-second: #858585;
$color-dark-grey-darker: #545454;
$color-charcoal-grey: #121212;

$background-black-low-opacity-2: rgba(0, 0, 0, 0.08);
$background-black-low-opacity: rgba(0, 0, 0, 0.1);
$background-black-lower-opacity: rgba(0, 0, 0, 0.35);
$background-black-lower-opacity-2: rgba(0, 0, 0, 0.6);
$color-light-blue: #eaf0f1;

$mobile-menu-links-shadow: rgba(34, 51, 84, 0.2) 0 0.125rem 0.5rem -0.188rem,
  rgba(34, 51, 84, 0.1) 0 0.313rem 1.375rem -0.25rem;
$background-color-mint-low-opacity: rgba(79, 134, 142, 0.12);
$grey-text: #6a6d6f;

$profile-email-text: #a0a0a0;
$profile-tab-text: #58595b;
$profile-tabs-underline-color: #a4a4a4;

$color-lavander-blue: #a59be1;
$color-lavander-blue-hover: #e6e5f5;
$color-lavander-blue-lightest: hsl(245, 45%, 93%);

$color-black-transparent: rgba(0, 0, 0, 0.05);

$payment-button-border-hover: rgba(79, 134, 142, 0.27);
$payment-button-background-hover: rgba(79, 134, 142, 0.12);

$certificate-form-sidebar-color: rgba(246, 246, 246, 255);
$certificate-form-sidebar-border: rgba(218, 218, 218, 1);
$certificate-form-sidebar-panel-color: rgba(241, 241, 241, 255);
$certificate-form-state-button-disabled: rgba(175, 175, 175, 1);
$certificate-form-sidebar-hover-color: rgba(79, 134, 142, 1);
$certificate-form-layer-hover: #007ff4;
$light-sand-background: #f6f6f6;
$background-picker-box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 0.0625rem,
  rgba(0, 0, 0, 0.15) 0 0.5rem 1rem;

$dark-text: #292d32;
$dark-grey: #b8b8b8;
$dark-brown-text: #5e5e5e;
$active-button-background: rgba(79, 134, 142, 0.17);

$color-green: #56ac00;

$active-template-shadow: rgba(79, 134, 142, 0.7);
$shadow-color: #0000000d;
$dark-gray-semi-transparent: rgba(34, 34, 34, 0.7);

$popover-list-item-hover: rgb(244, 246, 248);
$popover-list-item-disabled: rgba(99, 115, 129, 0.5);
$popover-content-color: rgb(33, 43, 54);
$dark-bluish-grey: rgb(33, 43, 54);

$modal-close-button: #ffffff40;
$progress-bar: #4f868e;
$progress-bar-background: #4f868e30;

$popper-background-hover: #e9f0f1;

$archived-certificate-badge-background: #878787;

$lock-transparent-background: #4f868e2b;

$disabled-certificate-creation-box-background: rgba(79, 134, 142, 0.122);

$in-progress-label-background: #e2a226;
$attribute-button-background: rgba(241, 243, 244, 1);
$attribute-background: rgba(218, 218, 218, 1);

$date-badge-text-color: #4f868e;

$shapes-coming-soon-overlay: rgba(0, 0, 0, 0.35);
