@import '../../../styles/variables';
@import '../../../styles/mixins';

.mainContainer {
  display: flex;
  flex-direction: column;
  top: 4.625rem;
  left: 0;
  right: 0;
  position: absolute;
  background-color: $color-white;
  width: 100%;
  height: 93%;
  align-items: center;
  z-index: 99;

  .innerContainer {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 2rem 0.9375rem;

    .companyContainer {
      margin-top: 0;
      margin-bottom: 1.3125rem;
    }

    .mobileMenuLinks {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-family: $font-manrope;
      font-size: 0.9375rem;

      @include bp(xl) {
        gap: 1.25rem;
      }

      .labelWithIconContainer {
        display: flex;
        align-items: center;
        gap: 0.625rem;

        &:hover {
          color: $primary-main;
        }

        .labelIcon {
          div {
            display: flex;
            align-items: center;
          }
        }
      }

      .arrowIcon {
        color: $primary-main;
      }

      .link,
      .becomeOrganiser {
        color: $grey-text;
        border-top: 0.0625rem solid $color-grey-background;
        width: 100%;
        text-align: left;
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          color: $primary-main;
        }

        &.isLast {
          border-bottom: 0.0625rem solid $color-grey-background;
        }
      }

      .becomeOrganiser {
        border-bottom: 0.0625rem solid $color-grey-background;

        .becomeOrganiserItem {
          display: flex;
          gap: 0.625rem;
        }
      }

      .roleSelectionContainer {
        width: 100%;
        border-bottom: 0.0625rem solid $color-grey-background;
        :global(.MuiAutocomplete-input) {
          pointer-events: none;
        }

        :global(.MuiOutlinedInput-root) {
          background-color: transparent;
          width: 100%;
          border: none;
          color: $primary-main;
          font-weight: 500;
          font-family: $font-manrope;
          font-size: 0.9375rem;
          padding: 0 !important;
          cursor: pointer;

          ::selection {
            background: transparent;
          }

          input {
            cursor: pointer;
          }
        }
      }
    }

    .subMenuContainer {
      width: 100%;

      .backButtonContainer {
        display: flex;
        gap: 0.19rem;
        color: $primary-main;
        transition: 0.2s;
        font-family: $font-manrope;
        font-size: 0.9375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;

        &:hover {
          cursor: pointer;
          color: $primary-dark;
        }
      }

      .selectedItem {
        margin-top: 1.75rem;
        margin-bottom: 1.125rem;
        display: flex;
        align-items: center;
        gap: 0.625rem;
        color: $primary-main;
        font-size: 0.9375rem;
        font-weight: 500;
        font-family: $font-manrope;

        .icon {
          div {
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .ababaLogo {
      position: absolute;
      bottom: 1rem;
      z-index: 100;
      padding-bottom: 0.7rem;
    }

    .version {
      font-family: $font-manrope;
      font-weight: 400;
      color: $grey-text;
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      z-index: 100;
      padding-bottom: 0.7rem;
    }
  }
}

.popper {
  border: 0.0625rem solid $color-light-grey;
  border-radius: 0.25rem;
  z-index: 1000;

  * {
    font-family: $font-manrope !important;
    font-size: 1.0625rem;
    font-weight: 400;
    color: $grey-text;
  }

  :global(.MuiAutocomplete-option[aria-selected='true']) {
    background-color: $primary-main-background !important;
    color: $primary-main;
    font-weight: 500;
  }
}
