@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.signaturePurchaseSecondStepContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include bp(s) {
    margin: 0 3.69rem 2.22rem 3.75rem;
  }

  @include bp(sm) {
    margin: 0 5.69rem 4.44rem 5.75rem;
  }

  @include bp(l) {
    margin: 0 11.69rem 9.44rem 11.75rem;
  }

  .backButtonContainer {
    display: flex;
    align-self: flex-start;
    margin-top: 1.37rem;
    gap: 0.19rem;
    color: $primary-main;
    transition: 0.2s;
    font-family: $font-manrope;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    order: 1;

    &:hover {
      cursor: pointer;
      color: $primary-dark;
    }

    @include bp(s) {
      order: 2;
    }
  }

  .signaturePurchaseSecondStepTitle {
    text-align: center;
    font-family: $font-manrope;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.12rem;
    order: 2;

    @include bp(s) {
      margin-top: 0;
      font-size: 1.875rem;
      order: 1;
    }
  }

  .description {
    margin-top: 0.56rem;
    font-size: 0.875rem;
    color: $color-dark-grey;

    @include bp(s) {
      margin-top: 1rem;
    }
  }

  .cardsContainer {
    margin-top: 1.38rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;
    order: 3;

    @include bp(s) {
      margin-top: 1.37rem;
    }

    .paymentChooseContainer {
      font-family: $font-manrope;
      padding: 1.5rem 0.87rem 2.06rem 1.25rem;
      display: flex;
      flex-direction: column;
      box-shadow: 0rem 0rem 1.25rem 0rem $color-black-transparent;
      border: 0.0625rem solid $color-light-grey;
      border-radius: 0.625rem;
      max-width: 18.75rem;

      @include bp(s) {
        width: 21rem;
        max-width: 21rem;
        padding: 1.69rem 2.31rem 2.38rem 2.31rem;
      }

      .title {
        margin-left: 0;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 2rem;
        color: $black-color;

        @include bp(s) {
          font-size: 1.375rem;
        }
      }

      .buttonsContainer {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        gap: 0.88rem;
        width: 16.625rem;

        @include bp(s) {
          gap: 1rem;
          margin-top: 1.38rem;
        }
      }
    }
  }
}
