@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.leftContainerInner {
  background-color: $background-color-mint-low-opacity;
  height: calc(100% - 2.44rem);
  border-radius: 1rem;
  margin: 0.7rem 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;

  @include bp(sm) {
    margin: 1.33rem 2.44rem;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @include bp(sm) {
      padding: 3rem 7.06rem 0;
    }

    .logo {
      width: 14.1875rem;
      align-self: center;

      @include bp(sm) {
        align-self: flex-start;
      }
    }

    .title {
      font-family: $font-manrope;
      font-weight: 500;
      font-size: 1.3rem;
      text-align: left;
      padding-top: 4.8rem;
      padding-bottom: 2.94rem;

      @include bp(sm) {
        font-size: 1.875rem;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      gap: 3.38rem;
      padding-bottom: 3rem;

      @include bp(sm) {
        flex-direction: row;
      }

      @include bp(l) {
        flex-direction: column;
        padding-bottom: 0;
      }

      @include bp(xxl) {
        flex-direction: row;
      }

      .infoItem {
        display: flex;
        flex-direction: column;
        gap: 0.6rem;

        .icon {
          width: 2.875rem;
          height: 2.875rem;
          display: flex;
          align-items: center;
        }

        span {
          color: $grey-text;
        }
      }
    }
  }

  .macbook {
    width: 80%;
    margin: auto;

    @include bp(l) {
      width: 95%;
    }
  }
}
