@import "../../styles/variables";
@import "../../styles/mixins";

.sidebarContainer {
  display: inline-block;
  width: 18.125rem;
  min-width: 18.125rem;
  color: $primary-main;
  z-index: 7;
  height: 100%;
  padding-bottom: 3.813rem;
  left: 0;
  top: 0;
  background: $bright-color;
  box-shadow: none;
  border-right: 0.063rem dashed $color-grey-light;

  .sidebarContent {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    .mainContent {
      display: flex;
      flex-direction: column;
      overflow: scroll;
      position: absolute;
      inset: 0;
      margin-right: -0.938rem;
      margin-bottom: -0.938rem;
      width: 100%;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {display: none;}

      .logoContainer {
        margin: 1.688rem 0 2.5rem;

        .logo {
          margin-left: 1.875rem;
          margin-right: 1.875rem;

          img {
            width: 100%;
          }
        }
      }

      .sidebarDivider {
        flex-shrink: 0;
        border: 0;
        height: 0.063rem;
        margin: 0.625rem 1.125rem 1rem;
        background: $color-grey-light;
      }

      .menuItemsContainer {
        display: block;
        margin-top: 1.875rem;

        .menuItems {
          list-style: none;
          margin: 0;
          position: relative;
          padding: 0.563rem;

          .menuItemsTitle {
            box-sizing: border-box;
            list-style: none;
            font-family: $jost;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.70rem;
            color: $black-color;
            padding: 0 1.406rem;
            line-height: 1.4;
          }

          .menuSubItemsContainer {
            display: block;

            .menuSubItems {
              padding: 0.563rem;
              list-style: none;
              margin: 0;
              position: relative;

              .subItemContainer {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                text-decoration: none;
                width: 100%;
                box-sizing: border-box;
                text-align: left;
                padding: 0.063rem 0;

                .subItem {
                  display: flex;
                  color: $color-grey;
                  width: 100%;
                  justify-content: flex-start;
                  padding: 0.875rem 1.688rem;
                  align-items: center;
                  position: relative;
                  box-sizing: border-box;
                  outline: 0;
                  border: 0;
                  cursor: pointer;
                  user-select: none;
                  vertical-align: middle;
                  appearance: none;
                  text-decoration: none;
                  font-family: $jost;
                  font-size: 0.875rem;
                  line-height: 1.75;
                  min-width: 4rem;
                  border-radius: 0;
                  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                  font-weight: 500;
                  text-transform: none;
                  margin: 0 0 0.25rem;

                  &:hover {
                    background-color: $background-light-grey;
                    border-radius: 0.75rem
                  }

                  &.activeSubItem {
                    background-color: $background-light-grey;
                    color: $primary-main;
                    font-weight: 600;
                    border-radius: 0.75rem
                  }

                  .navigationIcon {
                    margin-right: 0.625rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .bottomLogoWrapper {
      width: 100%;
      margin: auto 0 0.125rem 0;

      .bottomLogoDivider {
        width: 90%;
        flex-shrink: 0;
        border: 0;
        height: 0.063rem;
        background: rgba(255, 255, 255, 0.3);
      }

      .bottomLogoText {
        text-align: center;
        font-size: 0.75em;
        color: $color-grey;
      }

      .bottomLogo {
        margin: 0 auto;
        width: calc(18.125rem - 10.375rem);

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .bottomScrollContainer {
      position: absolute;
      height: 0.375rem;
      transition: opacity 200ms ease 0s;
      opacity: 0;
      right: 0.125rem;
      bottom: 0.125rem;
      left: 0.125rem;
      border-radius: 0.188rem;

      .bottomScroll {
        position: relative;
        display: block;
        height: 100%;
        cursor: pointer;
        border-radius: inherit;
        background-color: rgba(0, 0, 0, 0.2);
        width: 0;
      }
    }

    .rightScrollContainer {
      position: absolute;
      width: 0.375rem;
      transition: opacity 200ms ease 0s;
      opacity: 0;
      right: 0.125rem;
      bottom: 0.125rem;
      top: 0.125rem;
      border-radius: 0.188rem;

      .rightScroll {
        width: 0.313rem;
        background: rgba(34, 51, 84, 0.1);
        border-radius: 0.75rem;
        transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
}

