@import '../../../styles/variables';
@import '../../../styles/mixins';

.publicLayoutContainer {
  display: flex;
  flex-direction: column;

  @include bp(m) {
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .rightSection {
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      background-color: $certificate-form-sidebar-panel-color;
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-grey;
      border-radius: 0.375rem;
    }
  }
}
