@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.cardContainer {
  position: relative;
  width: 18.75rem;
  font-family: $font-manrope;
  padding: 1.69rem 1.13rem 1.87rem 1.25rem;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  box-shadow: 0rem 0rem 1.25rem 0rem $color-black-transparent;
  border: 0.0625rem solid $color-light-grey;
  border-radius: 0.625rem;

  @include bp(s) {
    width: 21rem;
    padding: 1.69rem 2.31rem 2.38rem 2.31rem;
  }

  &.isTheMostPopular {
    background-color: $primary-main;

    .title {
      color: $color-white;
    }

    .icon {
      color: $color-white;
    }

    .description {
      color: $color-grey-transparent;
    }

    .price {
      color: $color-white;
    }

    .line {
      background-color: $color-grey-more-transparent;
    }
    
    .prosContainer {
      .pros {
        color: $color-white;

        .checkmark {
          circle {
            fill: $color-white;
          }

          path {
            stroke: $primary-main;
          }
        }
      }
    }
  }

  .theMostPopularMessageContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9.0625rem;
    height: 2.625rem;
    border-radius: 0rem 0.5625rem;
    background: $color-lavander-blue;
    color: $color-white;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .icon {
    color: $primary-main;
  }

  .title {
    margin-top: 0.94rem;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2rem;
    color: $black-color;

    @include bp(s) {
      font-size: 1.375rem;
    }
  }

  .description {
    color: $color-dark-grey;
    font-weight: 400;
    line-height: 1.1rem;
    padding-top: 1.2rem;
    font-size: 0.875rem;
  }

  .price {
    margin-top: 1.25rem;
    font-size: 1.5rem;
    color: $black-color;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @include bp(s) {
      font-size: 1.875rem;
    }
  }

  .line {
    margin-top: 1.31rem;
    background-color: $color-light-grey;
    height: 0.0625rem;
    width: 100%;

    @include bp(s) {
      margin-top: 1.25rem;
    }
  }

  .prosContainer {
    display: flex;
    flex-direction: column;
    gap: 0.69rem;
    color: $black-color;
    font-size: 1rem;
    line-height: 2.25rem;
    padding-top: 1.4rem;

    @include bp(xs) {
      font-size: 1.125rem;
      line-height: 2.8125rem;
      padding-top: 1.75rem;
    }

    .pros {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      line-height: normal;
      color: $black-color;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      font-size: 0.9375rem;

      @include bp(s) {
        font-size: 1.125rem;
      }

      .checkmark {
        circle {
          fill: $primary-main;
        }

        path {
          stroke: $color-white;
        }
      }

      span {
        font-weight: 700;
      }
    }
  }

  .purchaseButton {
    margin-top: 1.81rem;
    gap: 0.83rem;
    font-weight: 500;

    @include bp(s) {
      margin-top: 3.06rem;
    }

    .bagIcon {
      padding-top: 0.4rem;
    }
  }
}
