@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.signaturePurchaseModal {
  :global(.MuiDialog-paperScrollPaper) {
    max-height: unset !important;

    @include bp(s) {
      max-height: calc(100% - 4rem) !important;
    }

    @include bp(m) {
      max-width: 99.6875rem !important;
    }
  }
}
