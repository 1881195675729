@import '../../styles/variables';
@import '../../styles/mixins';

.modalContainer {
  .topCloseButtonContainer {
    display: flex;
    justify-content: flex-end;

    .topCloseButton {
      svg {
        fill: $color-light-grey-third;
      }
    }
  }

  .successIconContainer {
    margin: 0 auto;
  }

  .description {
    text-align: center;
    color: $black-color;
    font-family: $font-inter;
    line-height: 1.875rem;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1.5rem;
  }

  .closeButton {
    margin: 2.56rem auto 0 auto;
    padding: 0.688rem 2.5rem;

    @include bp(s) {
      min-width: 23.0625rem;
      display: block;
      width: auto;
    }
  }

  :global(.MuiBackdrop-root) {
    background-color: $background-black-lower-opacity;
  }

  :global(.MuiDialogTitle-root) {
    display: block !important;
    text-align: center;
    color: $black-color;
    text-align: center;
    font-family: $font-manrope;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin-top: 1.38rem;
  }

  :global(.MuiPaper-root) {
    padding: 1rem;
    max-width: 39.5625rem;
    box-shadow: unset;

    @include bp(s) {
      padding: 3.19rem 4.31rem 3rem 3.81rem;
    }
  }

  :global(.MuiDialogContent-root) {
    padding: 0;
  }

  .bottomContent {
    margin-top: 0.94rem;
  }
}
