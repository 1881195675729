@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.loginSectionContainer {
  padding-top: 2.5rem;
  text-align: center;
  color: $lighter-bright-color;
  font-family: $font-manrope;

  .bottomMessage {
    margin-top: 1.75rem;
    color: $black-color;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      padding-left: 0.3125rem;
      font-weight: 500;
      text-decoration-line: underline;
      color: $primary-main;
      cursor: pointer;
      transition: 0.2s;

      &:hover{
        color: $primary-dark;
      }
    }
  }

  .socialButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.81rem;
  }

  .separatorWrapper {
    margin: 1.69rem 0;
  }

  .separator {
    text-align: center;
    margin-top: 1.69rem;
    position: relative;
  }

  .line {
    position: absolute;
    margin-top: 0.625rem;
    left: 0;
    right: 0;
    border-top: 0.063rem solid $lighter-bright-color;
  }

  .word {
    background-color: $bright-color;
    padding: 0 0.81rem;
    position: relative;
    z-index: 1;
  }
}
